<template>
  <div
    v-if="colors.length > 2 || sizes.length > 2"
    class="common-reviews__select-item"
    :da-eid="`comment_reviews_filter_${goodsId}`"
  >
    <!-- Review by product -->
    <span>{{ language.SHEIN_KEY_PC_22594 }}</span>
    <div
      class="common-reviews__select-box"
    >
      <div class="common-reviews__select-box-input">
        <!-- All/Selected -->
        <span>{{ selectedText }}</span>
        <sui_icon_more_down_14px />
      </div>

      <ClientOnly>
        <div class="review-filter_panel">
          <!-- 颜色筛选 -->
          <template v-if="colors.length > 2">
            <!-- Color -->
            <p>
              {{ language.SHEIN_KEY_PC_14546
              }}<template v-if="selectedColor">
                :
              </template>
              <span v-if="selectedColor"> {{ selectedColor.attr_value }}</span>
            </p>
            <!-- 颜色列表 -->
            <ul class="review-filter_panel-colors">
              <li
                v-for="(item, index) in colors"
                :key="index"
                :class="{ active: item.goods_id === selectedState.goods_id }"
                @click.stop="
                  onSelect('color', {
                    goods_id: item.goods_id,
                    goods_sn: item.goods_sn,
                    color_id: item.color_id,
                  })
                "
              >
                <img
                  :src="item.goods_color_image"
                  :alt="item.attr_value"
                />
                <s-popover
                  :content="item.attr_value"
                  placemen="top"
                  trigger="hover"
                />
              </li>
            </ul>
          </template>

          <!-- 尺码筛选 -->
          <template v-if="sizes.length > 2">
            <p>
              {{ language.SHEIN_KEY_PC_14656 }}
              <span
                v-if="currentCountry && hasLocalSize"
                class="color-gray-dark"
              >({{ currentCountry }})</span>
            </p>
            <ul class="review-filter_panel-sizes">
              <li
                v-for="(item, index) in sizes"
                :key="index"
                :class="{ active: item.attr_value_en === selectedState.size }"
                @click="onSelect('size', item)"
              >
                <span>
                  {{ item.attr_value }}
                  {{ getSizeCorrespond(item.attr_value_en) }}
                </span>
              </li>
            </ul>
          </template>
        </div>
      </ClientOnly>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { expose } from 'public/src/pages/common/analysis/directive.js'
import { getBubbleInfo } from 'public/src/pages/goods_detail_v2/utils/sizeInfoDesc.js'
import { sui_icon_more_down_14px } from '@shein-aidc/icon-vue3'
import { ClientOnly } from '@sheinfe/vue-client-only'


export default {
  name: 'ReviewFilter',
  directives: { expose },
  components: { sui_icon_more_down_14px, ClientOnly },
  props: {
    colors: {
      type: Array,
      default: () => [],
    },
    sizes: {
      type: Array,
      default: () => [],
    },
    selectedState: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapState([
      'detail',
      'language',
      'country',
      'polyAttrSize',
      'saleAttrList',
    ]),
    ...mapGetters([
      'skc_sale_attr'
    ]),
    // sizeList() {
    //   return this.size.attr_values?.filter(size => !!size.attr_value_id) ?? []
    // },
    // 选中的色彩项
    selectedColor() {
      const { colors, selectedState } = this
      const hitColor = colors.find(
        ({ goods_id }) => goods_id === selectedState.goods_id
      )
      if (hitColor && hitColor.goods_id) {
        return hitColor
      }
      return null
    },
    // 选中的尺码项
    selectedSize() {
      const { sizes, selectedState } = this
      const hitSize = sizes.find(({ attr_value_en }) => attr_value_en === selectedState.size)
      if (hitSize && hitSize.sizeId !== 'all') {
        return hitSize
      }
      return null
    },
    // 选框内展示的文本
    selectedText() {
      const { selectedColor, selectedSize, language } = this
      if (!selectedColor && !selectedSize) {
        // All
        return language.SHEIN_KEY_PC_14677
      }

      let str = ''
      if (selectedColor) {
        str = selectedColor.attr_value
      }
      if (selectedSize) {
        const sizeText = selectedSize.attr_value
        str = str ? `${str}, ${sizeText}` : sizeText
      }
      return str
    },
    // 当前地区
    currentCountry() {
      const lastSelectCountryInStore = typeof localStorage !== 'undefined' && localStorage.getItem('last_select_country')
      return (lastSelectCountryInStore === 'default' ? '' : lastSelectCountryInStore) || this.country
    },
    sizeCorrespondMap() {
      const attr_value_list = this.skc_sale_attr[0]?.attr_value_list
      if(!attr_value_list) return {}
      const map = Object.create(null)

      attr_value_list.forEach(item => {
        const { attr_value_name_en, attr_local_size_value } = item
        let val = attr_local_size_value
        // if(this.current_local_country) {
        //   val = attr_value_local_size?.find(i => i.country_code === this.current_local_country)?.attr_local_size_value ?? attr_local_size_value
        // }
        map[attr_value_name_en] = val
      })

      return map
    },
    // 本地尺码
    localSizeRule() {
      return (
        this.productIntroData?.multiLocalSize?.size_rule_list?.[
          this.currentCountry
        ] || []
      )
    },
    goodsId() {
      const { detail = {} } = this
      return detail.goods_id || ''
    },
    sizeInfoDes() {
      return this.productIntroData.sizeInfoDes || {}
    },
    bubbleInfo() {
      return getBubbleInfo(
        this.saleAttrList.skc_sale_attr,
        this.sizeInfoDes,
        [],
        this.language
      )
    },
    sizeSaleAttrId() {
      return this.bubbleInfo.sizeInfoDesAttrId || 87
    },
    hasLocalSize() {
      const { localSizeRule, polyAttrSize } = this
      if (!localSizeRule.length) {
        return false
      } else {
        for (const attrSizeItem of polyAttrSize) {
          for (const localSizeItem of localSizeRule) {
            if (attrSizeItem.attr_value_name_en === localSizeItem.name) {
              return true
            }
          }
        }
        return false
      }
    },
  },
  methods: {
    onSelect(type, param) {
      const { selectedState } = this

      // 颜色
      if (type === 'color') {
        const { goods_id, color_id } = param
        // 取消选中
        if (goods_id === selectedState.goods_id) {
          this.$emit('change', type, { goods_id: '', color_id: '' })
          return
        }
        // 多色条件下用goods_id筛选评论
        if (color_id == 447) param.color_id = ''
        this.$emit('change', type, param)
      }

      // 尺码
      if (type === 'size') {
        const { attr_value_id, attr_value_en } = param
        // 取消选中
        if (attr_value_en === selectedState.size) {
          this.$emit('change', type, '')
          return
        }
        const local_size = this.getSizeCorrespond(attr_value_en)
        daEventCenter.triggerNotice({
          daId: '1-8-5-48',
          extraData: {
            size_type: local_size ? `${attr_value_id}(${local_size})` : attr_value_id,
          },
        })
        this.$emit('change', type, attr_value_en)
      }
    },
    // 获取本地尺码字符
    getSizeCorrespond(sizeValue) {
      const { sizeCorrespondMap } = this
      if (!sizeCorrespondMap || sizeValue === 'one-size') {
        return ''
      }
      const correspond = sizeCorrespondMap[sizeValue]
      return correspond ? `(${correspond})` : ''
    },
  },
  emits: ['change'],
}
</script>

<style lang="less">
.common-reviews__select-box {
  position: relative;
  &:hover {
    .review-filter_panel {
      display: block;
    }
  }
}
.review-filter_panel {
  display: none;
  position: absolute;
  min-width: 280px;
  top: 44px;
  .right(0);
  z-index: @zindex-hack;
  padding: 16px;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.08);
  p {
    color: @sui_color_gray_dark1;
    font-size: 16px;
    font-weight: 700;

    span {
      color: @sui_color_gray_light1;
      font-weight: 400;
    }

    .color-gray-dark {
      color: @sui_color_gray_dark1;
    }
  }
}
.review-filter_panel-colors {
  margin-top: 12px;
  display: flex;
  li {
    margin-right: 6px;
    margin-bottom: 16px;
    padding: 2px;
    border: 2px solid transparent;
    border-radius: 18px;
    cursor: pointer;
    position: relative;

    &:hover {
      border: 1px solid @sui_color_gray_dark1;
      padding: 3px;
    }

    &.active {
      border: 2px solid @sui_color_gray_dark1;
      padding: 2px;
    }

    img {
      width: 28px;
      height: 28px;
      border-radius: 14px;
      overflow: hidden;
      box-sizing: border-box;
      border: 1px solid rgba(0, 0, 0, 0.08);
    }

    .sui-popover {
      position: absolute;
      left: -2px;
      top: 0px;
      width: 36px;
      height: 32px;
      border-radius: 14px;
      z-index: 1;

      & > span {
        display: inline-block;
        width: 100%;
        height: 100%;
      }
    }
  }
}
.review-filter_panel-sizes {
  margin-top: 8px;
  display: flex;
  flex-wrap: wrap;
  li {
    min-width: 36px;
    height: 32px;
    padding: 0 12px;
    border-radius: 16px;
    position: relative;
    top: 0;
    left: 0;
    border: 1px solid #e5e5e5;
    line-height: 32px;
    text-align: center;
    margin-right: 8px;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
      border-color: @sui_color_gray_dark1;
    }

    &.active {
      left: -1px;
      border-width: 2px;
      border-color: @sui_color_gray_dark1;
      margin-right: 6px;
      margin-bottom: 6px;
    }
  }
}
</style>
