<template>
  <div
    v-show="panelShow && showTrueToSize"
    :class="[
      'common-reviews__averate',
      !has_fit ? 'common-reviews__averate_center' : '',
    ]"
  >
    <div 
      class="common-reviews__left-wrap" 
    >
      <!-- 左侧平均分 -->
      <div
        class="common-reviews__averate-item ave-rate-box"
        :class="aceRateBoxClassName"
      >
        <!-- <div class="name">
          {{ language.SHEIN_KEY_PC_14660 }}
        </div> -->
        <div
          v-expose="{ id: '1-8-5-53' }"
          class="ave-rate"
          tabindex="0"
          role=""
          :aria-label="`${language.SHEIN_KEY_PC_14660}${commentRankAverage}`"
        >
          <div class="rate-num">
            {{ commentRankAverage }}
          </div>
          <CommonRate :rank-num="commentRankAverage" />
          <div class="rate-num-small">
            {{ commentRankAverage }}
          </div>
        </div>
        <div v-if="reviewPolicyInfo?.reviewPolicyUrl">
          <span
            v-if="reviewPolicyInfo?.reviewPolicyHasPopover"
            class="common-reviews__guidance"
          >
            <span
              class="common-reviews__guidance-text"
              @click="goToGuidance"
            >{{ language.SHEIN_KEY_PC_30730 || 'Richtlinie für Kundenbewertungen' }}</span>
            <s-popover
              :append-to-body="true"
              theme="light"
              placemen="top-end"
              trigger="hover"
              :prop-style="{
                'font-size': '12px',
                'padding': '8px',
                'width': '201px',
                'overflow-wrap': 'break-word',
                'word-break': 'break-word',
                'white-space': 'pre-wrap',
                'border-radius': '2px',
              }"
              :show-close-icon="false"
              :content="language.SHEIN_KEY_PC_31892"
            >
              <template #reference>
                <Icon
                  name="sui_icon_doubt_14px_1"
                  size="12px"
                  color="#666"
                />
              </template>
            </s-popover>
          </span>
          <span
            v-else
            class="common-reviews__guidance"
            @click="goToGuidance"
          >
            <span class="common-reviews__guidance-text">{{ language.SHEIN_KEY_PC_30730 || 'Richtlinie für Kundenbewertungen' }}</span>
            <Icon
              name="sui_icon_doubt_14px_1"
              size="12px"
              color="#666"
            />
          </span>
        </div>
        <!-- 排名 下方 -->
        <div
          v-if="isShowRank && isShowMultiRate"
          class="rank-box"
        >
          <span class="icon">
            <Icon 
              name="sui_icon_like_completed_20px" 
              size="10px" 
              color="#fff" 
            />
          </span>
          <div
            class="rank-text" 
            v-html="rankText"
          ></div>
        </div>
      </div>

      <!-- 中间多维评分 新样式布局展示-->
      <div
        v-if="isShowMultiRate"
        class="common-reviews__averate-item multi-rate new-middle__multi-rate"
      >
        <div class="multi-rate-flex-box">
          <div>
            <ul class="multi-rate-box">
              <li
                v-for="tag in currentMultiDimeRate"
                :key="tag.id"
                class="multi-rate-item"
              >
                <span>{{ tag.tagName }}</span>
                <span class="score">{{ tag.tag_score }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- 排名 右侧 -->
      <div
        v-if="!isShowMultiRate && isShowRank"
        class="rank-box rank-box-right"
      >
        <span class="icon">
          <Icon 
            name="sui_icon_like_completed_20px" 
            size="10px" 
            color="#fff" 
          />
        </span>
        <div
          class="rank-text" 
          v-html="rankText"
        ></div>
      </div>
    </div>

    <!-- 右侧尺码匹配 -->
    <div
      v-if="showTrueToSize && has_fit"
      class="common-reviews__averate-item right-fit-rate"
      :style="{'padding-top': isPopup ? 0 : ''}"
    >
      <div class="name">
        {{ htmlDecode({ text: language.SHEIN_KEY_PC_14655 }) }}
      </div>
      <!-- 水平布局 -->
      <div class="fit-title">
        {{ language.SHEIN_KEY_PC_14647 }}
      </div>
      <div 
        class="horizontal"
      >
        <div class="fit-item">
          <span class="fit-name">{{ language.SHEIN_KEY_PC_14651 }}</span>
          <div class="fit-progress">
            <div class="common-reviews__progress">
              <div
                class="progress-bar"
                :style="{ width: percentRateData.percent_overall_fit.small }"
              ></div>
            </div>
            <span>{{ percentRateData.percent_overall_fit.small }}</span>
          </div>
        </div>
        <div class="fit-item">
          <span class="fit-name">{{ language.SHEIN_KEY_PC_14650 }}</span>
          <div class="fit-progress">
            <div class="common-reviews__progress">
              <div
                class="progress-bar"
                :style="{ width: percentRateData.percent_overall_fit.true_size }"
              ></div>
            </div>
            <span>{{ percentRateData.percent_overall_fit.true_size }}</span>
          </div>
        </div>
        <div class="fit-item">
          <span class="fit-name">{{ language.SHEIN_KEY_PC_14652 }}</span>
          <div class="fit-progress">
            <div class="common-reviews__progress">
              <div
                class="progress-bar"
                :style="{ width: percentRateData.percent_overall_fit.large }"
              ></div>
            </div>
            <span>{{ percentRateData.percent_overall_fit.large }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import CommonRate from 'public/src/pages/goods_detail_v2/components/CommonRate.vue'
import { htmlDecode } from '@shein/common-function'
import { expose } from 'public/src/pages/common/analysis/directive.js'
import { SPopover } from '@shein-aidc/sui-popover'
import { Icon } from '@shein-aidc/icon-vue3'
import { template } from '@shein/common-function'
import schttp from 'public/src/services/schttp'

const { SiteUID } = gbCommonInfo

export default {
  name: 'AverageRate',
  directives: { expose },
  components: {
    CommonRate,
    SPopover,
    Icon,
  },
  props: {
    panelShow: {
      type: Boolean,
      default: false,
    },
    percentRateData: {
      type: Object,
      default: () => ({}),
    },
    // showTrueToSize: {
    //   type: Boolean,
    //   default: false,
    // },
    direction: {
      type: String,
      default: 'column',
    },
    position: {
      type: String,
      default: 'detail',
    },
    reviewsAbt: {
      type: Object,
      default: () => ({}),
    },
    screenAbtTspConfig: {
      type: Object,
      default: () => ({}),
    },
    reviewPolicyInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      SiteUID,
      commentRankBaseline: 20, // 切线
    }
  },
  computed: {
    ...mapState([
      'detail',
      'language',
      'only_local_status',
      'comment_rank_average',
      // 'multiDimensionRate',
    ]),
    ...mapGetters([
      'local_rating',
      'has_fit',
      'sel_tag_score',
      'cat_id',
      // 'screenAbtConfig',
      // 'currentCat',
    ]),
    showTrueToSize() {
      let value = false
      const { percent_overall_fit = {} } = this.percentRateData
      Object.keys(percent_overall_fit || {}).forEach(key => {
        if (percent_overall_fit && percent_overall_fit[key].replace('%', '') > 0) {
          value = true
        }
      })
      return value
    },

    rankAbt() {
      return this.reviewsAbt?.Reviewchange?.param?.Raterank === 'Show'
    },
    commentRankAverage() {
      const { percentRateData, local_rating } = this
      if (this.only_local_status) {
        return local_rating || null
      }
      return this.comment_rank_average || percentRateData?.comment_rank_average || null
    },
    // 是否展示排名文案
    isShowRank() {
      if(
        this.rankAbt && 
        [1, 2].includes(+this.percentRateData?.rankPercentInfo?.rankMultiLangShowType) &&
        (this.commentRankAverage >= 4)
      ) {
        return true
      }
      
      return false
    },

    rankText() {
      const { rankPercentInfo = {} } = this.percentRateData
      const { 
        advancedRankMultiLang,
        rankMultiLangShowType,
        inCatNameMultiLang,
        topRankMultiLang,
        topRankPercent,
      } = rankPercentInfo // rankMultiLangShowType:1-分值=5 2-分值4～5 3-不展示
      let text = ''
      if(+rankMultiLangShowType === 1) {
        text = `<span class="r-color">${topRankMultiLang}</span> ${inCatNameMultiLang}`
      } else if(+rankMultiLangShowType === 2) {
        text = `<span class="r-color">${template(topRankPercent + '%', advancedRankMultiLang)}</span> ${inCatNameMultiLang}`
      }
      return text
    },
    isPopup() {
      return this.position === 'popup'
    },
    isShowMultiRate() {
      const subrating = this.screenAbtTspConfig.subrating?.param?.sub_rating
      return this.currentMultiDimeRate.length && (!subrating || subrating === 'Yes') // Yes 该情况为默认情况，abt参数为空时也走此分支
    },
    // 当前多维评论分数（本地or全域）
    currentMultiDimeRate() {
      return this.sel_tag_score
    },

    // 左侧总分栏类名
    aceRateBoxClassName() {
      const { has_fit } = this
      return {
        'common-reviews__averate-item_center': !has_fit,
        'left-rate-num': true,
        // 'right-rate-num': !multiDimensionRate,
      }
    },
  },
  mounted() {
    this.getApolloConfig()
  },
  methods: {
    htmlDecode,
    async getApolloConfig() {
      let res = await schttp({
        method: 'POST',
        url: '/api/config/apolloConfig/query',
        data: { apolloKeys: 'SIZE_TIPS_CONFIG' }
      })
      const { SIZE_TIPS_CONFIG } = res?.info ?? {}
      this.commentRankBaseline = SIZE_TIPS_CONFIG?.commentRankValue || 20
    },
    goToGuidance() {
      window.open(this.reviewPolicyInfo?.reviewPolicyUrl)
    },
  },
}
</script>

<style lang="less">
.common-reviews__averate {
  // display: flex;
  min-height: 148px;
  background-color: #f7f8fa;
  &_center {
    height: auto;
  }
  .common-reviews__left-wrap {
    display: flex;
    &.right-rate-num {
      width: 50%;
    }
    // .new-middle__multi-rate {
    //   display: none;
    // }
  }
}


.common-reviews__averate-item {
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .name {
    margin-bottom: 15px;
    font-size: 16px;
    color: #222;
  }

  // 左侧评分栏
  &.ave-rate-box {
    box-sizing: border-box;

    &.left-rate-num {
      max-width: 272px;
      padding-right: 0;
      .rate-num-small {
        display: none;
      }
    }
    &.right-rate-num {
      // width: 50%;
      .rate-num {
        display: none;
      }
    }
  }

  .ave-rate {
    display: flex;
    align-items: center;

    .rate-num {
      margin-right: 4px;
      font-size: 36px;
      line-height: 46px;
      font-weight: 700;
      color: @sui_color_black;
      /* rw:begin */
      font-family: 'Adieu';
    }

    .rate-num-small {
      margin-left: 4px;
      font-size: 20px;
      color: #222;
      font-weight: 700;
    }
  }

  // 中间多维评分框
  &.multi-rate {
    width: 35%;
  }

  .multi-rate-flex-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: content-box;
    max-width: 240px;
    height: 100%;
    box-sizing: border-box;

    & > div {
      padding-left: 36px;
      border-left: 1px solid @sui_color_gray_weak1;
    }
  }

  .multi-rate-box {
    display: inline-block;
  }

  .multi-rate-item {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    line-height: 16px;
    color: #222;
    margin-top: 8px;
    &:first-child {
      margin-top: 0;
    }

    .score {
      font-weight: 700;
      margin-left: 24px;
    }
  }


  // 右侧尺码匹配
  .fit-item {
    display: flex;
    align-items: center;
    color: #222;
    & + .fit-item {
      margin-top: 10px;
    }
  }

  .fit-name {
    width: 80px;
  }
  // 水平布局下的样式 
  .fit-title {
    color: #222;
    font-family: Arial;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding-bottom: 6px;
  }
  .horizontal {
    display: flex;
    align-items: center;
    font-size: 12px;
    .fit-item {
      display: flex;
      flex-direction: column;
      color: #222;
      margin-right: 48px;
      margin-top: 0;
    }

    .fit-name {
      width: 100%;
    }
    .fit-progress {
      display: flex;
      align-items: center
    }
  }
}
.common-reviews__progress {
  .margin-r(15px);
  width: 100px;
  height: 4px;
  background-color: #e4e4e4;
  border-radius: 4px;
  .progress-bar {
    background-color: #222;
    width: 0;
    height: 100%;
    transition: width 0.5s ease-in-out;
    border-radius: 4px;
    /* rw:begin */
    background-color: @sui_color_honor;
  }
}

.common-reviews__guidance {
  cursor: pointer;
  color: var(---sui_color_gray_dark2, #666);
  font-family: "SF Pro";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.common-reviews__guidance-text{
    margin-right: 4px;
  }

  // 排名百分比
  .rank-box {
    display: flex;
    align-items: center;
    color: #000;
    font-family: "SF Pro";
    font-size: 14px;
    line-height: normal;
    padding-top: 8px;
    font-weight: 700;
    .rank-text {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .r-color {
      color: #EAAB00;
      font-weight: 700;
    }

    .icon {
        width: 18px;
        height: 18px;
        flex-shrink: 0;
        margin-right: 4px;
        background: #EAAB00;
        border-radius: 50%;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #fff;
      }
  }
    .rank-box-right {
      padding: 0 0 0 18px;
      margin: 12px 18px;
      border-left: 1px solid @sui_color_gray_weak1;
    }
</style>
